import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import LayoutCustom from "../../components/LayoutCustom";
import Footer from "../../components/Footer";
import SEO from "../../components/SEO";

const AboutUs = ({ data }) => {

    const aboutus_introduction = data.allMarkdownRemark.nodes;
    const { html } = data.markdownRemark;
    const { title, subTitle } = data.markdownRemark.frontmatter;

	return (
		<LayoutCustom>
            <SEO title="About Us" description="Appvity consists of a team of professionals that aspires to build productivity software that enables information professionals to work effectively. We build products that focus on Azure and Office 365 cloud platform." meta={[{ name: `keywords`, content: ["collaboration", "task management"] }]} pathname="/about-us" />
			<div className="main-content-padding">
				<div className="app-aboutus-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <div className="app-section-aboutus-detail">
                            <Row>
                                <Col xs={12} md={12} lg={5} className="app-aboutus-detail-col-custom">
                                    {aboutus_introduction.map((res) => (
                                        <div className="app-aboutus-detail-left" key={res.id} dangerouslySetInnerHTML={{ __html: res.html }} />
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={1}></Col>
                                <Col xs={12} md={12} lg={6}>
                                    <div className="app-aboutus-detail-right">
                                        <img src="/app-aboutus-introduction.png" alt="app aboutus introduction" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="app-our-history" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <div className="app-section-our-history-detail">
                            <Row>
                                <Col xs={12} md={12} lg={5}>
                                    <div className="app-our-history-detail-left">
                                        <h5>{subTitle}</h5>
                                        <div className="app-title">
                                            <h2>{title}</h2>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: html }} />
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={7}></Col>
                            </Row>
                        </div>
                    </Container>
                    <div className="footer-custom">
						<Footer />
					</div>
                </div>
			</div>
		</LayoutCustom>
	)
}

export default AboutUs;

export const query = graphql`
    query AboutUsIntroductionPage {
        allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "aboutus_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        markdownRemark(frontmatter: {directory: {eq: "aboutus_history"}}) {
            html
            frontmatter {
                title
                subTitle
            }
            id
        }
    }
`;
